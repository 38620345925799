import React from 'react'
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function EcommerceShowcaseSlider({ecommerceSettings, ecommarcedata}) {
    const settings = {...ecommerceSettings}
    return (
        <>
            <Slider {...settings}>
                {ecommarcedata.map(item=>(
                    <div className='ecommerce-inner-slide'>
                        <div className={`ecommerce-image overflow-hidden ${item.ecommarceclassview}`}>
                            <img src={item.ecommarceimageUrl} alt='Our Gallery' title='' className={`w-100 hide-responsive-image ${item.hidebrandresponsive}`} />
                            <img src={item.MobResponsiveImage} alt='' title='' className={`w-100 d-none show-responsive-image ${item.brandresponsive}`} />
                        </div>
                        <div className='ecommerce-slide-text rounded-4 ms-auto me-auto'>
                            <h6 className='theme-font-family mt-3 mb-3'>{item.ecommerceSubTitle}</h6>
                            <h5 className='theme-font-family'>{item.ecommerceTitle}</h5>
                        </div>
                    </div>
                ))}
            </Slider>
        </>
    )
}

export default EcommerceShowcaseSlider
